@import "../../stylesheets/index";
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
} // To remove extra padding from input button... .button is the class name of the input button

%button {
  background: $cool-gray-94;
  border: 0.1rem solid $cool-gray-88;
  border-radius: 3rem;
  color: $cool-gray-30;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0 0.3rem;
  padding: 0.3rem 1.6rem;
  text-align: center;
  text-decoration: none;
}

.button,
a.button {
  @extend %button;

  &:hover {
    background: $cool-gray-88;
    border: 0.1rem solid $cool-gray-88;
    color: $cool-gray-30;
  }

  &:active {
    background: $cool-gray-35;
    border: 0.1rem solid $cool-gray-35;
    color: $cool-gray-90;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &-dark {
    background: $cool-gray-24;
    border: 0.1rem solid $cool-gray-18;
    color: $cool-gray-80;

    &:hover {
      background: $cool-gray-18;
      border: 0.1rem solid transparent;
      color: $cool-gray-90;
    }

    &:active {
      background: $cool-gray-95;
      border: 0.1rem solid transparent;
      color: $cool-gray-30;
    }

    &--over-image {
      background: rgba($cool-gray-30, 0.9);
      border: 0.1rem solid $cool-gray-24;
      color: $cool-gray-80;

      &:hover {
        background: rgba($cool-gray-24, 0.9);
        border: 0.1rem solid transparent;
        color: $cool-gray-90;
      }

      &:active {
        background: rgba($cool-gray-95, 0.9);
        border: 0.1rem solid $cool-gray-93;
        color: $cool-gray-30;
      }
    }
  }

  &--large {
    margin: 0.4rem;
    padding: 0.5rem 1.9rem;
  }

  &--huge {
    font-size: 1.8rem;
    line-height: 1.45;
    margin: 0.4rem;
    padding: 1.2rem 2.8rem;
  }

  &--small {
    font-size: 1.2rem;
    margin: 0.3rem;
    padding: 0.4rem 1.5rem;
  }

  &--tiny {
    font-size: 1.2rem;
    margin: 0.2rem;
    padding: 0.2rem 0.7rem;
  }

  &--disabled {
    border: 0.1rem solid transparent;
    opacity: 0.4;
    pointer-events: none;
  }

  &--full-width {
    display: block;
    width: 100%;
  }

  &--danger {
    background: rgba($attracting-peach, 0.15);
    border: 0.1rem solid rgba($attracting-peach, 0.3);
    color: $attracting-peach;

    &:hover {
      background: rgba($attracting-peach, 0.3);
      border: 0.1rem solid rgba($attracting-peach, 0.3);
      color: rgba($attracting-peach, 0.3);
    }

    &:active {
      background: $attracting-peach;
      border: 0.1rem solid $attracting-peach;
      color: $white;
    }
  }

  &--over-image {
    background: rgba($white, 0.9);
    border: 0.1rem solid $cool-gray-94;
    color: $cool-gray-30;

    &:hover {
      background: rgba($cool-gray-94, 0.9);
      border: 0.1rem solid transparent;
      color: $cool-gray-20;
    }

    &:active {
      background: rgba($cool-gray-35, 0.9);
      border: 0.1rem solid $cool-gray-30;
      color: $cool-gray-90;
    }
  }
}
