@import "../../stylesheets/index";

.selectbox {
  width: 100%;

  &__label {
    align-items: center;
    color: $cool-gray-30;
    display: flex;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 0 0.3rem;

    &--disabled {
      opacity: 0.4;
    }

    &--tiny {
      font-size: 1.2rem;
    }

    &--small {
      font-size: 1.4rem;
    }

    &--large {
      font-size: 1.6rem;
    }

    &--huge {
      font-size: 1.8rem;
    }
  }

  &__field {
    .simple-select.react-selectize.default.root-node:not(.open).disabled .react-selectize-control {
      background: $cool-gray-94;
      border: 0.1rem solid $cool-gray-94;
    }

    .react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container {
      display: none;
    }

    .simple-select.react-selectize.default.root-node:not(.open) .react-selectize-control {
      background: $cool-gray-99;
      border-radius: 4px;
      border: 0.1rem solid $cool-gray-85;
      box-sizing: border-box;
      font-weight: normal;
      padding: 0;
    }

    .simple-select.react-selectize.default.root-node.open .react-selectize-control {
      background: $cool-gray-98;
      border: 0.1rem solid $cool-gray-85;
      border-radius: 4px;
      box-sizing: border-box;
      font-weight: normal;
      padding: 0;
    }

    .react-selectize.dropdown-menu.default .option-wrapper.highlight {
      background: $cool-gray-88;
    }

    .react-selectize.root-node {
      width: 100%;

      .react-selectize-control {
        align-items: center;
        display: flex;
        height: 3.2rem;

        .resizable-input {
          background: none;
          border: none;
          outline: none;
          font-size: 1em;
          margin: 2px;
          padding: 4px 0px;
          vertical-align: middle;
        }

        .value-wrapper {
          color: $cool-gray-30;
          font-size: 1.6rem;
        }
      }
    }

    .react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
      color: $cool-gray-30;
      font-size: 1.6rem;
    }

    .react-selectize.dropdown-menu.default:not(.flipped) {
      background: $cool-gray-98;
      font-size: 1.6rem;

      .option-wrapper .simple-option {
        color: $cool-gray-30;
      }
    }

    &--tiny {
      .react-selectize.root-node .react-selectize-control {
        align-items: center;
        display: flex;
        height: 2.4rem;

        .value-wrapper {
          color: $cool-gray-30;
          font-size: 1.2rem;
        }
      }

      .react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
        color: $cool-gray-30;
        font-size: 1.2rem;
      }

      .react-selectize.dropdown-menu.default:not(.flipped) {
        background: $cool-gray-98;
        font-size: 1.2rem;

        .option-wrapper .simple-option {
          color: $cool-gray-30;
        }
      }
    }

    &--small {
      .react-selectize.root-node .react-selectize-control {
        align-items: center;
        display: flex;
        height: 2.8rem;

        .value-wrapper {
          color: $cool-gray-30;
          font-size: 1.4rem;
        }
      }

      .react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
        color: $cool-gray-30;
        font-size: 1.4rem;
      }

      .react-selectize.dropdown-menu.default:not(.flipped) {
        background: $cool-gray-98;
        font-size: 1.4rem;

        .option-wrapper .simple-option {
          color: $cool-gray-30;
        }
      }
    }

    &--large {
      .react-selectize.root-node .react-selectize-control {
        align-items: center;
        display: flex;
        height: 3.6rem;

        .value-wrapper {
          color: $cool-gray-30;
          font-size: 1.6rem;
          padding-left: 0.4rem;
        }
      }

      .react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
        color: $cool-gray-30;
        font-size: 1.6rem;
      }

      .react-selectize.dropdown-menu.default:not(.flipped) {
        background: $cool-gray-98;
        font-size: 1.6rem;

        .option-wrapper .simple-option {
          color: $cool-gray-30;
        }
      }
    }

    &--huge {
      .react-selectize.root-node .react-selectize-control {
        align-items: center;
        display: flex;
        height: 5.2rem;

        .value-wrapper {
          color: $cool-gray-30;
          font-size: 1.8rem;
          padding-left: 0.8rem;
        }
      }

      .react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
        color: $cool-gray-30;
        font-size: 1.8rem;
      }

      .react-selectize.dropdown-menu.default:not(.flipped) {
        background: $cool-gray-98;
        font-size: 1.8rem;

        .option-wrapper .simple-option {
          color: $cool-gray-30;
        }
      }
    }

    &--error {
      .simple-select.react-selectize.default.root-node:not(.open) .react-selectize-control {
        background: rgba($attracting-peach, 0.05);
        border: 0.1rem solid rgba($attracting-peach, 0.3);
        font-weight: normal;
        padding: 0;
      }
    }
  }

  &__error {
    color: $attracting-peach;
    font-size: 1.4rem;
    font-style: italic;
    padding-left: 0.1rem;

    &--tiny {
      font-size: 1rem;
    }

    &--small {
      font-size: 1.2rem;
    }

    &--large {
      font-size: 1.4rem;
    }

    &--huge {
      font-size: 1.6rem;
    }
  }
}

.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container,
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
}

.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  width: 32px;
}

.react-selectize.root-node
  .react-selectize-control
  .react-selectize-search-field-and-selected-values {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 30px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.react-selectize.default.root-node
  .react-selectize-control
  .react-selectize-search-field-and-selected-values {
  padding-left: 5px;
}

.react-selectize.dropdown-menu.default:not(.flipped) {
  border-color: #b3b3b3 #ccc #d9d9d9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.react-selectize.dropdown-menu.default {
  background: #fff;
  border: 1px solid #ccc;
  margin-top: -1px;
}

// Fix for IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.react-selectize.root-node .react-selectize-control .react-selectize-placeholder{
    top: 0rem;
  }
}
