@import "../../stylesheets/index";
.label-hint {
  cursor: pointer;
  display: inline-block;
  height: 1.6rem;
  margin: 0 0 0 0.6rem;
  position: relative;
  width: 1.6rem;

  &:hover .label-hint__popup {
    opacity: 1;
    visibility: visible;
  }

  > svg {
    fill: $cool-gray-30;
    height: 100%;
    width: 100%;
  }

  &__popup {
    background: $cool-gray-40;
    border-radius: 0.3rem;
    bottom: 105%;
    box-shadow: 0 0.3rem 0.6rem 0 rgba($black, 0.2);
    box-sizing: border-box;
    color: $cool-gray-90;
    font-size: 1.2rem;
    left: 105%;
    line-height: 1.4em;
    opacity: 0;
    padding: 1.5rem 2rem;
    position: absolute;
    transition: 0.2s;
    visibility: hidden;
    width: 25rem;
    z-index: 1;
  }

  &--tiny {
    height: 1.2rem;
    width: 1.2rem;
  }

  &--small {
    height: 1.4rem;
    width: 1.4rem;
  }

  &--large {
    height: 1.8rem;
    width: 1.8rem;
  }

  &--huge {
    height: 2rem;
    width: 2rem;
  }

  &--no-margin {
    margin: 0;
  }
}
