@import "./deprecated-variables";

// Latest colors from styleguide

$cool-gray-99: #fbfcfe;
$cool-gray-98: #f8f9fd;
$cool-gray-97: #f6f6f8;
$cool-gray-96: #f2f4f7;
$cool-gray-95: #eff1f6;
$cool-gray-94: #eceef3;
$cool-gray-93: #eaebef;
$cool-gray-90: #e0e3ea;
$cool-gray-88: #dadde4;
$cool-gray-85: #d2d4dc;
$cool-gray-80: #c4c7ce;
$cool-gray-75: #b6b9c0;
$cool-gray-70: #a8abb2;
$cool-gray-60: #8e9198;
$cool-gray-50: #74777e;
$cool-gray-40: #5c5e65;
$cool-gray-35: #505259;
$cool-gray-30: #44474d;
$cool-gray-29: #42444a;
$cool-gray-25: #393b41;
$cool-gray-24: #37393f;
$cool-gray-20: #2e3036;
$cool-gray-18: #2a2c32;
$cool-gray-15: #202429;
$cool-gray-10: #191c21;
$highlighter: #f9b93c;

$mystified-earth: #c44847;
$approaching-pistachio: #00b575;
$attracting-peach: #ff605d;
$precocious-azure: #2a496f;

// Viewport declarations for responsive layouts

$large-desktop: 1200px; // large desktops
$desktop-tab: 991px; // desktops, landscape and portrait tabs
$tab-mobile: 767px; // portrait tabs and landscape mobiles
$mobile: 480px; // landscape and portrait mobiles

// Variables for new grid system

$gutter: 1rem;
$base-width: 117rem;
$column-width: 100% / 12;
$grid-count: 12;
