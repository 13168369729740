@import "../../stylesheets/index";

.text-field {
    &__box {
      background: $cool-gray-98;
      border: 0.1rem solid $cool-gray-85;
      border-radius: 0.3rem;
      color: $cool-gray-30;
      font-size: 1.6rem;
      padding: 0.3rem 1.6rem;
      width: 100%;
    }
  
    &:focus {
      background: $cool-gray-96;
      border-color: $cool-gray-40;
      outline: none;
    }
  
    &--small {
      font-size: 1.2rem;
      padding: 0.4rem 1.5rem;
    }
  
    &--tiny {
      font-size: 1.2rem;
      padding: 0.2rem 0.7rem;
    }
  
    &--large {
      padding: 0.5rem 1.9rem;
    }
  
    &--huge {
      font-size: 1.8rem;
      padding: 1.3rem 2.8rem;
    }
  
    &--disabled {
      background: $cool-gray-94;
      border-color: $cool-gray-94;
      pointer-events: none;
  
      ~ * svg {
        opacity: 0.2;
      }
    }
  
    &--error {
      background: rgba($attracting-peach, 0.05);
      border-color: rgba($attracting-peach, 0.3);
    }
  
    &-holder {
      position: relative;
    }
  
    &__label {
      align-items: center;
      color: $cool-gray-30;
      display: flex;
      font-size: 1.6rem;
      margin: 0 0 0.3rem;
  
      &--tiny {
        font-size: 1.2rem;
      }
  
      &--small {
        font-size: 1.4rem;
      }
  
      &--huge {
        font-size: 1.8rem;
      }
  
      &--disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  
    &-dark {
      .text-field {
        background: $cool-gray-29;
        border-color: $cool-gray-15;
        color: $cool-gray-80;
  
        &:focus {
          background: $cool-gray-25;
          border-color: $cool-gray-80;
        }
  
        &--error {
          background: rgba($attracting-peach, 0.05);
          border-color: rgba($attracting-peach, 0.3);
        }
  
        &.text-field--disabled {
          background: $cool-gray-35;
          border-color: $cool-gray-35;
        }
      }
  
      .text-field__label {
        color: $cool-gray-80;
      }
    }
  
    &__error {
      color: $attracting-peach;
      display: block;
      font-size: 1.4rem;
      font-style: italic;
      margin: 0.3rem 0 0;
  
      &--tiny {
        font-size: 1rem;
      }
  
      &--small {
        font-size: 1.2rem;
      }
  
      &--huge {
        font-size: 1.6rem;
      }
    }
  }