@import "../../stylesheets/index";

.input {
  &__field {
    background: $cool-gray-98;
    border: 0.1rem solid $cool-gray-85;
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: $cool-gray-30;
    font-size: 1.6rem;
    height: 3.2rem;
    padding: 0.3rem 1.6rem;
    width: 100%;

    &:focus {
      background: $cool-gray-96;
      border-color: $cool-gray-40;
      outline: none;
    }

    &--small {
      font-size: 1.2rem;
      height: 2.8rem;
      padding: 0.4rem 1.5rem;
    }

    &--tiny {
      font-size: 1.2rem;
      height: 2.4rem;
      padding: 0.2rem 0.7rem;
    }

    &--large {
      height: 3.6rem;
      padding: 0.5rem 1.9rem;
    }

    &--huge {
      font-size: 1.8rem;
      height: 5.2rem;
      padding: 1.3rem 2.8rem;
    }

    &--disabled {
      background: $cool-gray-94;
      border-color: $cool-gray-94;
      pointer-events: none;

      ~ * svg {
        opacity: 0.2;
      }
    }

    &--error {
      background: rgba($attracting-peach, 0.05);
      border-color: rgba($attracting-peach, 0.3);
    }

    &-holder {
      position: relative;
    }
  }

  &__label {
    align-items: center;
    color: $cool-gray-30;
    display: flex;
    font-size: 1.6rem;
    margin: 0 0 0.3rem;

    &--tiny {
      font-size: 1.2rem;
    }

    &--small {
      font-size: 1.4rem;
    }

    &--huge {
      font-size: 1.8rem;
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__pre-icon,
  &__post-icon {
    height: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.8rem;

    svg {
      fill: $cool-gray-30;
      height: 100%;
      width: 100%;
    }
  }

  &__pre-icon {
    left: 0.7rem;

    &--small {
      height: 1.6rem;
      left: 0.5rem;
      width: 1.6rem;
    }

    &--tiny {
      height: 1.4rem;
      left: 0.5rem;
      width: 1.4rem;
    }

    &--large {
      height: 2rem;
      left: 0.8rem;
      width: 2rem;
    }

    &--huge {
      height: 2.4rem;
      left: 1.4rem;
      width: 2.4rem;
    }
  }

  &__post-icon {
    right: 0.7rem;

    &--small {
      height: 1.6rem;
      right: 0.5rem;
      width: 1.6rem;
    }

    &--tiny {
      height: 1.4rem;
      right: 0.5rem;
      width: 1.4rem;
    }

    &--large {
      height: 2rem;
      right: 0.8rem;
      width: 2rem;
    }

    &--huge {
      height: 2.4rem;
      right: 1.4rem;
      width: 2.4rem;
    }
  }

  &-with-pre-icon {
    .input__field {
      padding-left: 3.2rem;
    }

    &--small {
      .input__field {
        padding-left: 2.4rem;
      }
    }

    &--tiny {
      .input__field {
        padding-left: 2.2rem;
      }
    }

    &--large {
      .input__field {
        padding-left: 3.4rem;
      }
    }

    &--huge {
      .input__field {
        padding-left: 4.8rem;
      }
    }
  }

  &-with-post-icon {
    .input__field {
      padding-right: 3.2rem;
    }

    &--small {
      .input__field {
        padding-right: 2.4rem;
      }
    }

    &--tiny {
      .input__field {
        padding-right: 2.2rem;
      }
    }

    &--large {
      .input__field {
        padding-right: 3.4rem;
      }
    }

    &--huge {
      .input__field {
        padding-right: 4.8rem;
      }
    }
  }

  &-dark {
    .input__field {
      background: $cool-gray-29;
      border-color: $cool-gray-15;
      color: $cool-gray-80;

      &:focus {
        background: $cool-gray-25;
        border-color: $cool-gray-80;
      }

      &--error {
        background: rgba($attracting-peach, 0.05);
        border-color: rgba($attracting-peach, 0.3);
      }

      &.input__field--disabled {
        background: $cool-gray-35;
        border-color: $cool-gray-35;
      }
    }

    .input__label {
      color: $cool-gray-80;
    }

    .input__pre-icon,
    .input__post-icon {
      svg {
        fill: $cool-gray-80;
      }
    }
  }

  &__error {
    color: $attracting-peach;
    display: block;
    font-size: 1.4rem;
    font-style: italic;
    margin: 0.3rem 0 0;

    &--tiny {
      font-size: 1rem;
    }

    &--small {
      font-size: 1.2rem;
    }

    &--huge {
      font-size: 1.6rem;
    }
  }
}

.input__field-holder {
  .input__field {
    @extend .input__field;
  }
}
